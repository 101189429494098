/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './CertificateModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';
import MUISelect from '../../../components/inputs/MUISelect';
//import MUICheckBox from '../../../components/inputs/MUICheckBox';

const CertificateModalView = ({
  certificate,
  countries,
  onInputChange,
  onClose,
  onSave,
  disabled
}) => {
  const title = certificate?.id ? 'Editar certificado' : 'Agregar certificado';
  return (
    <ModalComponent
      open={true}
      title={title}
      element={certificate}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="certificatemodal">
        <MUITextField
          id="name"
          label="Nombre *"
          defaultValue={certificate?.name}
          type="text"
          name="name"
          onChange={onInputChange}
        />

        <MUITextField
          label="Certificado *"
          defaultValue={certificate?.certification}
          type="text"
          name="certification"
          onChange={onInputChange}
        />
        <MUITextField
          id="address"
          label="Dirección"
          defaultValue={certificate?.address}
          type="text"
          name="address"
          onChange={onInputChange}
        />
        <MUITextField
          label="Teléfono *"
          defaultValue={certificate?.phone}
          type="text"
          name="phone"
          onChange={onInputChange}
        />
        <MUITextField
          id="location"
          label="Ubicación"
          defaultValue={certificate?.location}
          type="text"
          name="location"
          onChange={onInputChange}
        />
        <MUISelect
          label="Pais *"
          defaultValue={certificate?.country_id}
          items={countries}
          idSelector="id"
          selector="name_es"
          noSelectedLabel="Select"
          name="country_id"
          onChange={onInputChange}
          className="certificatemodal_rolTitle"
        />
        <MUITextField
          id="email"
          label="Email"
          defaultValue={certificate?.email}
          type="text"
          name="email"
          onChange={onInputChange}
        />
        <MUITextField
          label="facebook"
          defaultValue={certificate?.facebook}
          type="text"
          name="facebook"
          onChange={onInputChange}
        />
        <MUITextField
          id="company"
          label="Compañía"
          defaultValue={certificate?.company}
          type="text"
          name="company"
          onChange={onInputChange}
        />

        <MUITextField
          label="Instagram"
          defaultValue={certificate?.instagram}
          type="text"
          name="instagram"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default CertificateModalView;
